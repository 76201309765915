import { useLazyQuery } from "@vue/apollo-composable";
import { useI18n } from "vue-i18n";
import { useToast } from "vue-toastification";
import possibleRouteParamArrayToString from "~/utils/possibleRouteParamArrayToString";

import useSharedApolloService from "~/composables/sharedApolloService";
import {
  activeChallengesQuery,
  completedChallengesQuery,
  countsQuery,
  likeChallengeMutation,
  respectedVideosQuery,
  specificUserProfileQuery,
  uploadedVideosQuery,
} from "~/graphql/profile";
import { useProfileStore } from "~/stores/profilePage";

export default () => {
  const route = useRoute();
  const userStore = useUserStore();
  const profileStore = useProfileStore();
  const { username: currentUserUsername, showPrivateChallenges } =
    toRefs(userStore);
  const { challengeable } = toRefs(profileStore);
  const toast = useToast();
  const { t } = useI18n();

  const sharedApolloService = useSharedApolloService();

  const userIsOwner = computed(
    () =>
      currentUserUsername && route.params.username === currentUserUsername.value
  );

  const specificUsername = computed(() =>
    possibleRouteParamArrayToString(route.params.username)
  );

  const userIsChallengeable = computed(() => !!challengeable?.value);

  // Queries and mutations
  const getChallengesVariables = computed(() => ({
    username: specificUsername.value,
    showPrivate: showPrivateChallenges.value,
  }));

  const getCounts = useLazyQuery(countsQuery, getChallengesVariables);

  const getProfile = useLazyQuery(
    specificUserProfileQuery,
    computed(() => ({
      username: specificUsername.value,
    }))
  );

  const getRespectedVideos = useLazyQuery(
    respectedVideosQuery,
    getChallengesVariables
  );

  const getActiveChallenges = useLazyQuery(
    activeChallengesQuery,
    getChallengesVariables
  );

  const getCompletedChallenges = useLazyQuery(
    completedChallengesQuery,
    getChallengesVariables
  );

  const getUploadedVideos = useLazyQuery(
    uploadedVideosQuery,
    getChallengesVariables
  );

  getProfile.onResult((result) => {
    if (result.loading || !result.data?.getUserProfile?.profile) return;
    profileStore.update(result.data.getUserProfile.profile);
  });

  getCounts.onResult((result) => {
    if (result.loading || !result.data?.getUserProfile?.profile) return;
    profileStore.update(result.data.getUserProfile.profile);
  });

  getActiveChallenges.onResult((result) => {
    if (result.loading || !result.data?.getUserProfile?.profile) return;
    profileStore.update(result.data.getUserProfile.profile);
  });

  getCompletedChallenges.onResult((result) => {
    if (result.loading || !result.data?.getUserProfile?.profile) return;
    profileStore.update(result.data.getUserProfile.profile);
  });

  getUploadedVideos.onResult((result) => {
    if (result.loading || !result.data?.getUserProfile?.profile) return;
    profileStore.update(result.data.getUserProfile.profile);
  });

  getRespectedVideos.onResult((result) => {
    if (result.loading || !result.data?.getUserProfile?.profile) return;
    profileStore.update(result.data.getUserProfile.profile);
  });

  const likeChallenge = sharedApolloService.useSharedMutation(
    likeChallengeMutation
  );

  likeChallenge.onError(() => {
    toast.error(t("toast.like_challenge_error"));
  });

  // Effects
  watch(showPrivateChallenges, async () => {
    const routeName =
      typeof route.name === "string" ? (route.name as string) : "";

    // TODO: check for a better way of reloading these queries
    // TODO: the problem is that, when a route is renamed, these checks break
    if (routeName.includes("active-challenges"))
      getActiveChallenges.refetch(getChallengesVariables.value);
    if (routeName.includes("completed-challenges"))
      getCompletedChallenges.refetch(getChallengesVariables.value);
    if (routeName.includes("uploaded-videos")) getUploadedVideos.refetch();
    if (routeName.includes("respected-videos")) getRespectedVideos.refetch();

    getCounts.load() || getCounts.refetch();
  });

  return {
    // Pass through profile store to minimize duplicate code
    profileStore,

    // Computed properties
    userIsOwner,
    userIsChallengeable,

    // Queries
    getUploadedVideos,
    getActiveChallenges,
    getProfile,
    getCompletedChallenges,
    getRespectedVideos,

    // Mutations
    likeChallenge,
  };
};
