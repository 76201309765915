import { graphql } from "~/gql";

export const specificUserProfileQuery = graphql(`
  query specificUserProfile($username: String!, $showPrivate: Boolean = false) {
    getUserProfile(username: $username, showPrivate: $showPrivate) {
      status {
        success
        identifier
        message
      }
      profile {
        id
        username
        displayName
        bio
        avatar
        challengeable
        publicProfile
        respectedVideosCount
        completedChallengesCount
        activeChallengesCount
        uploadedVideosCount
      }
    }
  }
`);

export const countsQuery = graphql(`
  query countsQuery($username: String!, $showPrivate: Boolean = false) {
    getUserProfile(username: $username, showPrivate: $showPrivate) {
      status {
        success
        identifier
        message
      }
      profile {
        respectedVideosCount
        completedChallengesCount
        activeChallengesCount
        uploadedVideosCount
      }
    }
  }
`);

export const uploadedVideosQuery = graphql(`
  query uploadedVideos($username: String!, $showPrivate: Boolean = false) {
    getUserProfile(username: $username, showPrivate: $showPrivate) {
      status {
        success
        identifier
        message
      }
      profile {
        uploadedVideos {
          slug
          challengeName
          challengeDescription
          challengeReward
          hasLiked
          challengeLikesCount
          challengeLikes {
            user {
              username
              displayName
              avatar
            }
          }
          challengeVideos {
            videoSource
            aspectMode
          }
          owner {
            username
            avatar
          }
          challengedUser {
            username
            avatar
          }
        }
      }
    }
  }
`);

export const activeChallengesQuery = graphql(`
  query activeChallenges($username: String!, $showPrivate: Boolean = false) {
    getUserProfile(username: $username, showPrivate: $showPrivate) {
      status {
        success
        identifier
        message
      }
      profile {
        activeChallenges {
          challengeName
          challengeDescription
          challengeReward
          challengeType
          challengeLikesCount
          visibility
          owner {
            username
            avatar
          }
          challengedUser {
            username
            avatar
          }
          slug
        }
      }
    }
  }
`);

export const completedChallengesQuery = graphql(`
  query completedChallenges($username: String!, $showPrivate: Boolean = false) {
    getUserProfile(username: $username, showPrivate: $showPrivate) {
      status {
        success
        identifier
        message
      }
      profile {
        completedChallenges {
          slug
          challengeName
          visibility
          owner {
            username
            avatar
          }
          challengedUser {
            username
            avatar
          }
          challengeDescription
          challengeReward
        }
      }
    }
  }
`);

export const respectedVideosQuery = graphql(`
  query respectedVideos($username: String!, $showPrivate: Boolean = false) {
    getUserProfile(username: $username, showPrivate: $showPrivate) {
      status {
        success
        identifier
        message
      }
      profile {
        respectedVideos {
          slug
          challengeName
          challengeDescription
          challengeReward
          hasLiked
          challengeLikesCount
          challengeLikes {
            user {
              username
              displayName
              avatar
            }
          }
          challengeVideos {
            videoSource
            aspectMode
          }
          owner {
            username
            avatar
          }
          challengedUser {
            username
            avatar
          }
        }
      }
    }
  }
`);

export const likeChallengeMutation = graphql(`
  mutation likeChallenge($input: ChallengeLikeInput!) {
    likeChallenge(input: $input) {
      liked
      unliked
    }
  }
`);
